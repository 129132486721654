import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import {
  ActiveHome,
  ActiveLearn,
  ActiveRanking,
  ActiveTodo,
  InActiveHome,
  NavLearnLogo,
  NavRangingLogo,
  NavToDoLogo,
  ProfileIcon,
  ProfileImage,
  HomeActiveMob,
  LearnActiveMob,
  TodoActiveMob,
  RankingActiveMob,
  ProfileActiveMob,
} from '../../../assets';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline';

const Footer = () => {
  const [activeNav, setActiveNav] = useState('home');
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.manageTheme?.theme?.inActiveMenuIcons);

  const activeClassName = 'active';
  const navActive = ({ isActive }) => (isActive ? activeClassName : undefined);

  useEffect(() => {
    if (
      location.search === '?tab=analytics' ||
      location.search === '?tab=yourSquad' ||
      location.search === '?tab=basicInfo' ||
      location.search === '?tab=badges'
    ) {
      window.scrollTo(0, 600);
    } else {
      window.scrollTo(0, 0);
    }

    setActiveNav(location.pathname);
  }, [location]);
  const navItems = [
    {
      title: 'Home',
      link: '/',
      path: 'home',
      activeIcon: <HomeIcon style={{fill:theme??'#00796e'}}/>,
      inActiveIcon: <HomeIcon />,
    },
    {
      title: 'Learn',
      link: '/learn',
      path: 'learn',
      activeIcon: <SchoolIcon style={{fill:theme??'#00796e'}}/>,
      inActiveIcon: <SchoolIcon />,
    },
    {
      title: 'To Do',
      link: '/to-do',
      path: 'to-do',
      activeIcon: <TimelineIcon style={{fill:theme?.inActiveMenuIcons??'#00796e'}}/>,
      inActiveIcon: <TimelineIcon n style={{fill:'#777777'}}/>,
    },
    {
      title: 'Rankings',
      link: '/rankings',
      path: 'rankings',
      activeIcon: <LeaderboardIcon  style={{fill:theme??'#00796e'}} />,
      inActiveIcon: <LeaderboardIcon />,
    },
    {
      title: 'Profile',
      link: '/profile',
      path: 'rankings',
      activeIcon: <PersonIcon style={{fill:theme??'#00796e'}} />,
      inActiveIcon: <PersonIcon />,
    },
  ];
  return (
    <>
      <div className='max__footer-wrapper d-none d-lg-block'>
        <div className='max__footer-container'>
          <p>© 2024 Maxlearn. {t('FOOTER.ALL_RIGHTS_RESERVED')}</p>
          <div className='privacy-n-terms'>
            <p>
              <Link className='footer__link' to='/privacy_policy'>
                {t('FOOTER.PRIVACY_POLICY')}
              </Link>
              |
              <Link className='footer__link' to='/terms_conditions'>
                {t('FOOTER.TERMS')}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className='navbar-footer-wrapper position-fixed d-block d-lg-none bottom-0 w-100'>
        <div className='max-footer-container navbar-footer-bar mt-3'>
          <ul className='navbar-footer-list d-flex justify-content-between ps-0 mb-0'>
            {navItems?.map((nav) => (
              <li
                className='d-flex justify-content-center  text-center'
                key={nav?.path}
              >
                <NavLink to={nav?.link}>
                  <div className='nav-icon'>
                    {activeNav === nav?.link
                      ? nav?.activeIcon
                      : nav?.inActiveIcon}
                  </div>
                  <p
                    className={
                      activeNav === nav?.link ? 'active-text-footer' : ''
                    }
                    style={{color:activeNav === nav?.link?theme:''}}
                  >
                    {nav.title}
                  </p>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
