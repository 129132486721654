import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import '../../../styles/questions/congratulation.scss';
import { CloseButton, CertTrophy, WhiteScoreDiamond } from '../../../assets';
import { fetchCertReport } from '../../../services/certs';
import { useTranslation } from 'react-i18next';

const HighScoreCard = ({ score, name }) => {
  const { correctAnswers, learningLevel, pointsEarned, totalQuestions } = score;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const changes = {
    " Awareness ": " Basic ",
    " Explanatory ": " Intermediate ",
    " Practitioner ": " Advanced ",
    "Awareness ": "Basic ",
    "Explanatory ": "Intermediate ",
    "Practitioner ": "Advanced ",
    " Awareness": " Basic",
    " Explanatory": " Intermediate",
    " Practitioner": " Advanced",
    Awareness: "Basic",
    Explanatory: "Intermediate",
    Practitioner: "Advanced",
    AWARENESS: "Basic",
    EXPLANATORY: "Intermediate",
    PRACTITIONER: "Advanced",
  };
  return (
    <div
      className='score'
      style={{
        backgroundImage: `url(${'https://res.cloudinary.com/dysdy7hjr/image/upload/v1684150986/Group_kb4qof.png'})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        minHeight: '100vh',
      }}
    >
      <div className='p-5'>
        <div className=' col-sm-12 justify-content-center '>
          <div className='d-flex justify-content-end '>
            <button className='detail_close' onClick={() => navigate('/learn')}>
              <p className='d-none'>cls</p>
              <CloseButton.default />
            </button>
          </div>
          <div className='d-flex  justify-content-center   mt-3'>
            <img
              src={CertTrophy.default}
              className='cup_img'
              alt='image trophy'
            />
          </div>
          <div className='score_intro s pt-4'>
            {t('REPORTS.SCORE.CONGRATS')} {name ?? ''}
          </div>
          <div className='score_aware'>
            <div className='pe-2'>
              {' '}
              <WhiteScoreDiamond.default />
            </div>

            {t('REPORTS.SCORE.LEARNING_LEVEL', { learningLevel: changes[learningLevel] })}
          </div>
          <div className='row m-0 justify-content-center mt-2 mx__score-dir'>
            <div className='col-12 col-sm-2 ml-score-points'>
              <h5 className='ml-score-txt-bg'>
                {correctAnswers} <span className='ml-score-txt-md'>of</span>{' '}
                {totalQuestions}
              </h5>
              <h5 className='ml-score-txt'>{t('REPORTS.SCORE.CORRECT')}</h5>
            </div>
            <div className='col-12 col-sm-2'>
              <h5 className='ml-score-txt-bg'>{pointsEarned}</h5>
              <h5 className='ml-score-txt'>
                {t('REPORTS.SCORE.EARNED_POINTS')}
              </h5>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-4 '>
            <Link to='/learn'>
              <Button className='explore_challenge'>
                {t('REPORTS.SCORE.CONTINUE_LEARNING')}
              </Button>
            </Link>
          </div>
          <div className='d-flex justify-content-center mt-3 '>
            <Button
              className='view_leaderboard'
              onClick={() => {
                navigate('/rankings');
              }}
            >
              {t('REPORTS.VIEW_LEADERBOARD')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighScoreCard;
