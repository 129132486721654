import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSeason: '',
};

const seasonSlice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    saveActiveSeason: (state, action) => {
      return {
        ...state,
        activeSeason: action?.payload,
      };
    },
    removeActiveSeason: (state, action) => {
      return {
        ...state,
        activeSeason: '',
      };
    },
  },
});
export const { saveActiveSeason, removeActiveSeason } = seasonSlice.actions;
export default seasonSlice.reducer;
