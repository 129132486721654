import React from 'react';
import {
  AnalyticsMenuMob,
  CloseButton,
  TranscriptIcon,
  TranscriptIconMob,
} from '../../../assets';
import { useSelector } from 'react-redux';

import {
  FaqIcon,
  LearnIcon,
  SquadIcon,
  BadgesIcon,
  ActivityIcon,
  LogoutImage,
  AnalyticsMenu,
  ProfileImage,
} from '../../../assets';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const navbarItems = [
  // {
  //   name: 'My Activies',
  //   img: <ActivityIcon.default />,
  //   link: '/to-do?tab=challenges',
  // },

  {
    name: 'Learn by Category',
    img: <LearnIcon.default />,
    link: '/learn/category-list',
  },
  {
    name: 'My Teams',
    img: <SquadIcon.default />,
    link: '/profile?tab=yourSquad',
  },
  {
    name: 'My Transcripts',
    img: <TranscriptIconMob.default />,
    link: '/transcript',
  },
  {
    name: 'My Badges',
    img: <BadgesIcon.default />,
    link: '/profile?tab=badges',
  },
  {
    name: 'My Analytics',
    img: <AnalyticsMenuMob.default />,
    link: '/profile?tab=analytics',
  },
  { name: 'Help Desk', img: <FaqIcon.default />, link: '/help' },
];

const Sidebar = ({ isOpen, onClose, handleLogoutModal }) => {
  const location = useLocation();
  const learnerDetail = useSelector((state) => state.learnerDetail);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  console.log(learnerDetail, 'this is values');
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='overlay' onClick={onClose}></div>
      <div className='sidebar-content  d-block d-lg-none'>
        <button className='close-button' onClick={onClose}>
          <p className='d-none'>close</p>
          <CloseButton.default />
        </button>
        <div className='nav-user-details'>
          <div className='user-details d-flex'>
            {learnerDetail?.profileImage === undefined ||
            learnerDetail?.profileImage === null ||
            learnerDetail?.profileImage === '' ? (
              <ProfileImage.default />
            ) : (
              <img
                className='user_profile-pic'
                src={learnerDetail?.profileImage}
                alt='Profile picture of a Maxlearn'
              />
            )}
            <div className='details'>
              <h6>{learnerDetail?.name}</h6>
              <div className='d-flex'>
                <p>{learnerDetail?.points} points</p>{' '}
                {/* <p>
                  {learnerDetail &&
                  learnerDetail?.rank &&
                  learnerDetail?.rank === 1
                    ? '1 st'
                    : learnerDetail?.rank === 2
                    ? '2 nd'
                    : learnerDetail?.rank === 3
                    ? '3 rd'
                    : `${learnerDetail?.rank} th`}{' '}
                  rank
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <ul className='sidebar-menu p-4'>
          {navbarItems.map((item) => {
            return (
              <li className='mb-2'>
                <a href={item.link}>
                  {item.img} {item.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div className='mx__mob-login-container'>
          <button onClick={handleLogoutModal}>
            <LogoutImage.default /> Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
