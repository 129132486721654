import { value } from 'dom7';
import React from 'react';
import SidebarModal from '../Common/CustomModal/SidebarModal';
import CardModalNotification from './CardModalNotification';
import Badge from '@mui/material/Badge';
import InfiniteScrollModal from '../Pagination/InfiniteScrollModal';
import { CenterLoadingBar } from '../loader/loader';

const data = [
  {
    name: 'A new challenge has been assigned to Your team.',
    time: '1 min',
    bgColor: 'purple',
  },
  {
    name: 'A new challenge has been assigned to Your team.',
    time: '6 min',
    bgColor: 'yellow',
  },
  {
    name: 'A new challenge has been assigned to Your team.',
    time: '7 min',
    bgColor: 'green',
  },
  {
    name: 'A new challenge has been assigned to Your team.',
    time: '12 min',
    bgColor: 'pink',
  },
];

const NotificationModal = ({
  show,
  onHide,
  notifications,
  page,
  count,
  notificationLoading,
  setCurrentPage,
  handleNotificationNavigation,
  markAllAsRead,
}) => {
  return (
    <SidebarModal
      show={show}
      onHide={onHide}
      title='Notifications'
      middle={notifications?.length ? true : false}
      middleTitle={
        <button onClick={markAllAsRead} className='mark-all-read'>
          Mark all as read
        </button>
      }
    >
      <div className='row w-100'>
        {notificationLoading && page == 0 ? (
          <CenterLoadingBar />
        ) : (
          <InfiniteScrollModal
            dataLength={notifications?.length}
            next={() => setCurrentPage(page + 1)}
            hasMore={notifications?.length < count}
          >
            {Array.isArray(notifications) ? (
              notifications?.length ? (
                notifications?.map((value, index) => {
                  return (
                    <div className='col-12' key={index}>
                      <CardModalNotification
                        data={value}
                        index={index}
                        handleNotificationNavigation={
                          handleNotificationNavigation
                        }
                      />
                    </div>
                  );
                })
              ) : (
                <span className='no-new-notification'>
                  No New Notifications
                </span>
              )
            ) : (
              <span className='no-new-notification'>No New Notifications</span>
            )}
          </InfiniteScrollModal>
        )}
      </div>
    </SidebarModal>
  );
};

export default NotificationModal;
