import React from 'react';
import CustomButton from '../Common/CustomButton/CustomButton';
import { Points, CloseButton, QuizMe } from '../../assets';
import MembersAvatar from '../Common/Avatar/MembersAvatar';
import { ButtonLoader } from '../loader/loader';

export const QuizDetailCard = ({
  name,
  description,
  handleClick,
  isLoading,
  handleClose,
  learnersLists,
}) => {
  return (
    <div className='position-relative'>
      <div className='container-fluid text-end'>
        <button
          className='detail_close detail_close-btn '
          onClick={handleClose}
        >
          <p className='d-none'>cls</p>
          <CloseButton.default />
        </button>
      </div>

      <div className='row detail__card-wrapper ml__margin-50 quiz-me-wrapper width-100'>
        <div className='col-12 col-lg-5 quiz__me-image '>
          <div
            className='detail__card-wrapper-left  h-100'
            style={{
              backgroundImage: `url(${QuizMe.default})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          ></div>
        </div>
        <div className={`col-12 col-lg-7 detail__card-between`}>
          <div>
            {learnersLists?.length > 0 && (
              <div className='partcipation'>
                <div className='partcipation__detail'>
                  <MembersAvatar
                    total={learnersLists?.length}
                    max={3}
                    team={learnersLists}
                  />
                  <p className='partcipation__detail-members mb-0'>
                    {learnersLists?.length} people are taking this challenge
                    now!
                  </p>
                </div>
              </div>
            )}

            <div className='detail__card__header mt-sm-5 mt-4'>
              <h4 className='detail__card__header-heading mb-0'>
                Hello {name},
              </h4>
            </div>

            <div className='detail__card__body mt-3'>
              <h3 className='detail__card__body-header mb-3'>Become a Pro!</h3>
              {description?.length
                ? description?.map((item) => (
                    <div className='detail__card__body-points mt-2'>
                      <div className='wrapper'>
                        <span>
                          <img
                            className='detail__card__body-points-img'
                            src={Points.default}
                            alt={Points.default}
                          />
                        </span>
                        <p className='detail__card__body-points-key detail__card__body-points-quiz-key'>
                          {item}
                        </p>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </div>

          <div className='quiz-me-btn'>
            <CustomButton
              disabledText={'Yet to start'}
              text={isLoading ? <ButtonLoader /> : 'Quiz Me'}
              handleClick={handleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
