import translationEN from './English.json';
import translationHI from './Hindi.json';
import translationSP from './Spanish.json';

const translatedLanguages = {
  'en-US': {
    translation: translationEN,
  },
  hi: {
    translation: translationHI,
  },
  sp: {
    translation: translationSP,
  },
};

export default translatedLanguages;
