import http from '../../api/api';
import { getCookie } from '../../utility/helper';

export const getHotTopicsOfTheDay = async (learnerId, pageNum) => {
  const response = await http.get(
    `/api/app/learn-module/hot-topics/${learnerId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const getContinueLearning = async (learnerId, pageNum) => {
  const response = await http.get(
    `/api/app/learn-module/attempted-topics-list/${learnerId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const getLearnByCategory = async (learnerId, pageNum) => {
  const response = await http.get(
    `/api/app/learn-module/list-all-category/${learnerId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const getCatrgoryWiseStats = async (learnerId, categoryId) => {
  const response = await http.get(
    `/api/app/learn-module/categorywise-stats/${learnerId}/${categoryId}`
  );
  return response.data;
};

export const getCatrgoryWiseSubjects = async (
  learnerId,
  categoryId,
  pageNum
) => {
  const response = await http.get(
    `/api/app/learn-module/list-all-subjects/${learnerId}/${categoryId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const getSubDetilsStats = async (learnerId, categoryId) => {
  const response = await http.get(
    `/api/app/learn-module/subjectwise-stats/${learnerId}/${categoryId}`
  );
  return response.data;
};

export const getSubjWiseTopics = async (learnerId, categoryId, pageNum) => {
  const response = await http.get(
    `/api/app/learn-module/list-all-topics/${learnerId}/${categoryId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const getTopiDetilsStats = async (learnerId, categoryId) => {
  const response = await http.get(
    `/api/app/learn-module/topicwise-stats/${learnerId}/${categoryId}`
  );
  return response.data;
};
export const getQuestionStatusCheck = async (id) => {
  const response = await http.get(
    `/api/admin/questions/check-question-exists/${id}`
  );
  return response.data;
};

export const getTopicFlashCards = async (categoryId) => {
  const response = await http.get(
    `/api/app/learn-module/list-all-flashcards/${categoryId}`
  );
  return response.data;
};

export const getTopicWiseDecks = async (categoryId, pageNum) => {
  const response = await http.get(
    `/api/app/learn-module/list-all-decks/${categoryId}?pageNum=${pageNum}`
  );
  return response.data;
};

export const startLearingQuizExam = async (payload) =>
  await http.post(`/api/app/learn-module/start-quiz-me`, payload);
export const getQuizLearners = async (learnModule, topic) => {
  const response = await http.get(
    `/api/app/learn-module/learners-taking-test-now/${learnModule}/${topic}`
  );
  return response.data;
};

export const getLearnQuizQuestions = async (learnModule, quiz) => {
  const response = await http.get(
    `/api/app/learn-module/pick-question/${learnModule}/${quiz}`
  );
  return response.data;
};

export const getLearnQuizResults = async (learnModule, quiz) => {
  const response = await http.get(
    `/api/app/learn-module/score-details/${learnModule}/${quiz}`
  );
  return response.data;
};

export const submitAnswerLearnQuizExam = async (payload) => {
  const response = await http.post(
    `/api/app/learn-module/attempt-question`,
    payload
  );
  return response.data;
};

export const getLearnTakingTestNow = async (learnModule, topicId) => {
  const response = await http.get(
    `/api/app/learn-module/learners-taking-test-now/${learnModule}/${topicId}`
  );
  return response.data;
};

export const getLeanrerPointsEarned = async (learnModule) => {
  const response = await http.get(
    `/api/app/leaderboard/points-earned/${learnModule}`
  );
  return response.data;
};

export const getLeanrerGoals = async (data) => {
  const id = getCookie('userid');
  try {
    const response = await http.get(`/api/app/goal/list-goals/${id}`, {
      params: data,
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
