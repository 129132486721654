import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  HomeNotificationLogo,
  HomeNotificationLogoMob,
  MaxLogo,
  HomeSearchLogo,
  NavLearnLogo,
  NavToDoLogo,
  NavRangingLogo,
  LogoutImage,
  ConsoleArrow,
  ProfileImage,
  HelpDesk,
  ActiveLearn,
  ActiveTodo,
  ActiveRanking,
  ActiveHome,
  InActiveHome,
  Console,
  bellIcon,
  UniversalSearch,
  UniversalClose,
  Hamburger,
  TranscriptIcon,
  SearchListIcon,
  AnalyticsMenu,
} from '../../../assets';
import NotificationModal from '../../Modals/NotificationModal';
import LogoutModal from '../CustomModal/LogoutModal';
import {
  checkForNewNotifcations,
  checkForUserNotifcations,
  getTopicForNotifcations,
  markAsSeenNotification,
  subscribeTopicForNotifcations,
  addFireBaseToken,
} from '../../../services/notification/notifications';
import { getFireBaseToken } from '../../firebase/Firebase';
import { universalSearch } from '../../../services/home';
import useDebounce from '../../CustomHooks/debounce';
import EmptySearch, {
  EnterThreeSearch,
} from '../../UI/EmptyScreens/EmptySearch';
import { LoadingBar, NavIconsLoader } from '../../loader/loader';
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar';
import SearchModal from './SearchModal';
import { setCookie } from '../../../utility/helper';
import MenuIcon from '@mui/icons-material/Menu';

import PersonIcon from '@mui/icons-material/Person';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
const Navbar = () => {
  const sw = navigator.serviceWorker;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchRef = useRef();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.manageTheme?.theme);
  const learnerDetail = useSelector((state) => state.learnerDetail);
  const [activeNav, setActiveNav] = useState('home');
  const [profileDropDown, setProfileDropDown] = useState(false);

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [isNewNotificationMob, setIsNewNotificationMob] = useState(false);
  const [userNotifcations, setUserNotifications] = useState([]);
  const [notificationData, setNotification] = useState('');
  const [fireBaseToken, setFireBaseToken] = useState('');
  const [topicsIds, setTopicsIds] = useState([]);

  const [showSearch, setShowSearch] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [universalSearchLoader, setUniversalSearchLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleSearchbar = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  const handleNotificationModal = () => {
    setShowNotificationModal(true);
    searchInputClose();
  };
  const handleSearchModal = () => {
    setIsSearchOpen(true);
  };
  const closeNotificationModal = () => {
    setShowNotificationModal(false);
  };
  const markAllAsRead = () => {
    if (userNotifcations?.length) {
      let ids = userNotifcations
        ?.filter((not) => not?.isRead === false)
        ?.map((item) => item?._id);
      if (ids?.length) {
        markAsSeen({
          learnerId: auth?.learnerId,
          notificationIds: ids,
        });
      }
    }
  };

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleLogoutModal = () => {
    setShowLogoutModal(true);
  };
  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const activeClassName = 'active';
  const navActive = ({ isActive }) => (isActive ? activeClassName : undefined);

  useEffect(() => {
    if (sw) {
      window.addEventListener('load', () => {
        sw.register('/firebase-messaging-sw.js')
          .then(() => sw.ready)
          .then(() => {
            sw.addEventListener('message', ({ data }) => {
              if (data?.notification?.title)
                setNotification({
                  title: data?.notification?.title,
                  body: data?.notification?.body,
                });
            });
          });
      });
    }
  }, [sw]);

  useEffect(() => {
    handeleFireBaseTokenSetup();
  }, []);

  const handeleFireBaseTokenSetup = async () => {
    try {
      const notificationRes = await getFireBaseToken();
      setFireBaseToken(notificationRes);
      setCookie('fireBaseToken', notificationRes);
    } catch (err) {}
  };

  useEffect(() => {
    if (fireBaseToken && auth?.learnerId)
      handleAddFirebaseToken(fireBaseToken, auth?.learnerId);
  }, [fireBaseToken, auth?.learnerId]);
  const handleAddFirebaseToken = async (tokenn, userId) => {
    let payload = {
      learnerId: userId,
      deviceToken: tokenn,
    };
    try {
      let res = await addFireBaseToken(payload);
    } catch (err) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const path = location.pathname.split('/');
    setActiveNav(location.pathname === '' ? 'home' : path[1]);
  }, [location]);

  const getTopic = async () => {
    let res = await getTopicForNotifcations(auth?.learnerId);
    let chall = res?.data?.challenge?.map((item) => item?._id);
    let certs = res?.data?.certs?.map((item) => item?._id);
    let quest = res?.data?.quests?.map((item) => item?._id);
    chall = [...chall, ...certs, ...quest];
    setTopicsIds(chall);
  };

  useEffect(() => {
    if (fireBaseToken && auth?.learnerId && notificationData?.title) {
      checkForNewNotifcation();
      checkForUserNotifcation(currentPage);
    }
  }, [fireBaseToken, auth?.learnerId, notificationData?.title, currentPage]);
  useEffect(() => {
    checkForNewNotifcation();
    checkForUserNotifcation(currentPage);
  }, [currentPage]);

  useEffect(() => {
    getTopic();
  }, []);

  useEffect(() => {
    if (showSearch) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  useEffect(() => {
    if (window !== top) {
        top.location = window.location;
    }
  }, []);

  const subscribeToTopics = async (payload) => {
    try {
      let res = await subscribeTopicForNotifcations(payload);
    } catch (err) {}
  };

  useEffect(() => {
    if (topicsIds?.length && fireBaseToken) {
      let payload = {
        deviceToken: fireBaseToken,
        topics: topicsIds,
      };
      subscribeToTopics(payload);
    }
  }, [topicsIds, fireBaseToken]);

  const checkForNewNotifcation = async () => {
    let res = await checkForNewNotifcations(auth?.learnerId);
    setIsNewNotification(res);
    setIsNewNotificationMob(res);
  };
  const checkForUserNotifcation = async (page, reset) => {
    let res = await checkForUserNotifcations(
      auth?.learnerId,
      page + 1,
      setNotificationLoading
    );
    setTotalCount(res?.data?.count);
    if (res?.data?.result?.length) {
      setUserNotifications((userNotifcations) => [
        ...userNotifcations,
        ...res?.data?.result,
      ]);
    }
  };
  const markAsSeen = async (payload, reset) => {
    let val;
    let res = await markAsSeenNotification(payload);
    if (res?.status == 200) {
      checkForNewNotifcation();
      let values = userNotifcations?.map((item) => ({
        ...item,
        isRead: payload?.notificationIds?.includes(item?._id)
          ? true
          : item?.isRead,
      }));
      setUserNotifications([...values]);
      val = true;
    }
    return val;
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  const searchInputClose = () => {
    setShowSearch(false);
    setSearchText('');
    setShowSearchDropdown(false);
  };

  useDebounce(
    async () => {
      if (searchText.length > 2) {
        setUniversalSearchLoader(true);
        const response = await universalSearch(searchText);
        setUniversalSearchLoader(false);
        if (response?.statusCode === 200) {
          setSearchResults(response?.data);
        }
      } else if (searchText.length === 0) {
        setSearchResults([]);
      }
    },
    [searchText],
    800
  );

  const navItems = [
    {
      title: t('NAV_ITEM.HOME_TAB'),
      link: '/',
      path: '',
      activeIcon: <HomeIcon style={{fill:theme?.inActiveMenuIcons??'#00796e'}}/>,
      inActiveIcon: <HomeOutlinedIcon style={{fill:'#777777'}}/>,
    },
    {
      title: t('NAV_ITEM.LEARN_TAB'),
      link: '/learn',
      path: 'learn',
      activeIcon: <SchoolIcon style={{fill:theme?.inActiveMenuIcons??'#00796e'}}/>,
      inActiveIcon: <SchoolIcon style={{fill:'#777777'}}/>,
    },
    {
      title: t('NAV_ITEM.TODO_TAB'),
      link: '/to-do',
      path: 'to-do',
      activeIcon: <TimelineIcon style={{fill:theme?.inActiveMenuIcons??'#00796e'}}/>,
      inActiveIcon: <TimelineIcon n style={{fill:'#777777'}}/>,
    },
    {
      title: t('NAV_ITEM.RANKING_TAB'),
      link: '/rankings',
      path: 'rankings',
      activeIcon: <LeaderboardIcon  style={{fill:theme?.inActiveMenuIcons??'#00796e'}} />,
      inActiveIcon: <LeaderboardIcon style={{fill:'#777777'}}/>,
    }
  ];

  const navigateToLearn = (id, type) => {
    switch (type) {
      case 'subject':
        navigate(`/learn/subject-detail?subject=${id}`);
        break;
      case 'category':
        navigate(`/learn/detail?category=${id}`);
        break;
      case 'topic':
        navigate(`/learn/topic-detail?topic=${id}`);
        break;
    }
  };

  const pluralWordings = (topic) => {
    const result = {
      subject: 'subjects',
      category: 'categories',
      topic: 'topics',
    };
    return result?.[topic];
  };

  const handleNotificationNavigation = async (data) => {
    let res;
    if (data?.isRead === false) {
      res = await markAsSeen({
        learnerId: auth?.learnerId,
        notificationIds: [data?._id],
      });
    }
    if ((data?.isRead === false && res == true) || data?.isRead === true) {
      switch (data?.data?.type) {
        case 'cert':
          navigate('/to-do/cert/start', {
            state: {
              type: 'cert',
              certID: data?.data?.certId,
            },
          });
          setShowNotificationModal(false);
          break;
        case 'quest':
          navigate(`/to-do/quest/start`, {
            state: {
              type: 'quest',
              id: data?.data?.questId,
            },
          });
          setShowNotificationModal(false);
          break;
        case 'challenge':
          navigate(`/to-do/challenge/detail/${data?.data?.challengeId}`, {
            state: { 'challenge-type': data?.data?.subType },
          });
          setShowNotificationModal(false);
          break;
        case 'goal':
          navigate(`/learn/topic-detail?topic=${data?.data?.topicId}`);
          setShowNotificationModal(false);
          break;
      }
    }
  };

  const searchHandler = () => {
    setShowSearch(true);
    setProfileDropDown(false);
  };
  return (
    <>
      <header className='home__navbar d-none d-lg-block'>
        <nav className='navbar navbar-expand-lg max__navbar'>
          <div className='container-fluid'>
            <Link className='navbar-brand' to='/'>
              <span className='mx-home_text-only'>Home</span>
              {!!theme?.loginLogoImage ? (
                <img
                  src={theme?.loginLogoImage}
                  alt='Logo'
                  className='max__logo'
                  style={{ height: 50 }}
                />
              ) : (
                <MaxLogo.default />
              )}
            </Link>
            {/* <button
              className='navbar-toggler'
              type='button'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasNavbar'
              aria-controls='offcanvasNavbar'
            >
              <span className='navbar-toggler-icon' />
            </button> */}
            <div
              className='offcanvas offcanvas-end'
              tabIndex='-1'
              id='offcanvasNavbar'
              aria-labelledby='offcanvasNavbarLabel'
            >
              <div className='offcanvas-header'>
                <h5 className='offcanvas-title' id='offcanvasNavbarLabel'>
                  Offcanvas
                </h5>
                <button
                  type='button'
                  className='btn-close text-reset'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                />
              </div>
              <div className='offcanvas-body'>
                {showSearch ? (
                  <div className='navbar-nav max__menunav-list justify-content-end flex-grow-1  pe-5'></div>
                ) : (
                  <ul className='navbar-nav max__menunav-list justify-content-end flex-grow-1 border-end pe-5'>
                    {navItems?.map((nav) =>
                      auth?.loading ? (
                        <li className='nav-loading'></li>
                      ) : (
                        <li key={nav?.path}>
                          <NavLink className={navActive} to={nav?.link}>
                            <span className='nav-icon'>
                              {activeNav === nav?.path
                                ? nav?.activeIcon
                                : nav?.inActiveIcon}
                            </span>
                            {nav?.title}
                          </NavLink>
                        </li>
                      )
                    )}
                  </ul>
                )}
                <div className='d-flex'>
                  <ul className='navbar-nav max__menunav-list ms-5'>
                    {showSearch ? (
                      <>
                        <li>
                          <div className='search__wrapper '>
                            <label htmlFor='search' className='d-none'>
                              search
                            </label>
                            <input
                              id='search'
                              ref={searchRef}
                              placeholder={t(
                                'HOME_PAGE.UNIVERSAL_SEARCH_PLACEHOLDER'
                              )}
                              value={searchText}
                              className='search_box expand__searchbar'
                              onChange={handleSearch}
                            />
                            <div>
                              <div className='search__icon'>
                                <UniversalSearch.default />
                              </div>
                              <div
                                className='search__close'
                                onClick={searchInputClose}
                              >
                                <UniversalClose.default />
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    ) : (
                      <li className='max__nav-search'>
                        {auth?.loading ? (
                          <NavIconsLoader />
                        ) : (
                          <div className='max__nav-search-container'>
                            <button className='btn' onClick={searchHandler}>
                              <p className='d-none'>search </p>
                              <HomeSearchLogo.default />
                            </button>
                          </div>
                        )}
                      </li>
                    )}

                    <li className='max__notification'>
                      {auth?.loading ? (
                        <NavIconsLoader />
                      ) : (
                        <div className='max__nav-notification-container'>
                          <button
                            id='notification'
                            onClick={handleNotificationModal}
                          >
                            <p className='d-none'>notification </p>
                            {isNewNotification ? (
                              <HomeNotificationLogo.default />
                            ) : (
                              <bellIcon.default />
                            )}
                          </button>
                        </div>
                      )}
                    </li>
                    <li className='max__nav-user'>
                      {auth?.loading ? (
                        <NavIconsLoader />
                      ) : (
                        <div className='max__nav-user-container'>
                          <button
                            id='profile-dropdown'
                            onClick={() => {
                              setProfileDropDown(
                                (previousState) => !previousState
                              );
                              searchInputClose();
                            }}
                          >
                            {learnerDetail?.profileImage === undefined ||
                            learnerDetail?.profileImage === null ||
                            learnerDetail?.profileImage === '' ? (
                              <ProfileImage.default />
                            ) : (
                              <img
                                className='user_profile-pic'
                                src={learnerDetail?.profileImage}
                                alt='Profile picture of a Maxlearn'
                              />
                            )}
                          </button>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {searchText.length > 2 ? (
          <div className='search__backdrop' onClick={searchInputClose}>
            <div className='search__dropdown'>
              {universalSearchLoader ? (
                <div className='my-5'>
                  <LoadingBar />
                </div>
              ) : (
                <>
                  <h2 className='search__header'>
                    Search result ({searchResults?.length || 0})
                  </h2>
                  {searchResults.length > 0 ? (
                    searchResults?.map((searchDetails) => (
                      <button
                        className='search__content'
                        key={searchDetails?._id}
                        onClick={() => {
                          navigateToLearn(
                            searchDetails?._id,
                            searchDetails?.type
                          );
                        }}
                      >
                        <div className='search__image'>
                          {searchDetails?.categoryIcon ? (
                            <img
                              src={searchDetails?.categoryIcon}
                              alt='icons'
                            />
                          ) : searchDetails?.subjectIcon ? (
                            <img src={searchDetails?.subjectIcon} alt='icons' />
                          ) : (
                            <div className='search__icon'>
                              <SearchListIcon.default />
                            </div>
                          )}
                        </div>
                        <div
                          className='search__text'
                          title={searchDetails?.title}
                        >
                          <h4>{searchDetails?.title}</h4>
                          <p>In {pluralWordings(searchDetails?.type)}</p>
                        </div>
                      </button>
                    ))
                  ) : (
                    <EmptySearch />
                  )}
                </>
              )}
            </div>
          </div>
        ) : searchText.length > 0 && searchText.length < 3 ? (
          <div className='search__backdrop' onClick={searchInputClose}>
            <div className='search__dropdown'>
              <EnterThreeSearch />
            </div>
          </div>
        ) : (
          ''
        )}

        {profileDropDown ? (
          <div
            className='dropblur'
            onClick={() => {
              setProfileDropDown(false);
            }}
          >
            <div className='dropDownProfile'>
              <ul className='dropdown__list-wrapper'>
                <li className='dropdown__list'>
                  {location.pathname === '/profile' ? (
                    <a href='/profile?tab=basicInfo' className='dropdown__list'>
                      <div className='image-logo'>
                        <ProfileImage.default />
                      </div>
                      {t('HOME_PAGE.MYPROFILE')}
                    </a>
                  ) : (
                    <Link
                      to='/profile?tab=basicInfo'
                      className='dropdown__list'
                    >
                      <div className='image-logo'>
                        <ProfileImage.default />
                      </div>
                      {t('HOME_PAGE.MYPROFILE')}
                    </Link>
                  )}
                </li>
                <li className='dropdown__list'>
                  {location.pathname === '/profile' ? (
                    <a href='/profile?tab=analytics' className='dropdown__list'>
                      <div className='image-logo'>
                        <AnalyticsMenu.default />
                      </div>
                      {t('PROFILE_PAGE.ANALYTICS_TAB')}
                    </a>
                  ) : (
                    <Link
                      to='/profile?tab=analytics'
                      className='dropdown__list'
                    >
                      <div className='image-logo'>
                        <AnalyticsMenu.default />
                      </div>
                      {t('PROFILE_PAGE.ANALYTICS_TAB')}
                    </Link>
                  )}
                </li>
                {/* <li className='dropdown__list  '>
                  <Link className='dropdown__list d-flex justify-content-between align-items-center'>
                    <div className='dropdown__list'>
                      <div className='image-logo'>
                        <Console.default />
                      </div>
                      {t('HOME_PAGE.CONSOLE')}
                    </div>

                    <div className='text-end'>
                      <ConsoleArrow.default />
                    </div>
                  </Link>
                </li> */}
                <li className='dropdown__list  '>
                  <Link
                    to={'/transcript'}
                    className='dropdown__list d-flex justify-content-between align-items-center'
                  >
                    <div className='dropdown__list'>
                      <div className='image-logo'>
                        <TranscriptIcon.default />
                      </div>
                      {t('HOME_PAGE.TRANSCRIPT')}
                    </div>
                  </Link>
                </li>
                <li className='dropdown__list '>
                  <Link to='/help' className='dropdown__list'>
                    <div className='image-logo'>
                      <HelpDesk.default />
                    </div>
                    {t('HOME_PAGE.HELP&SUPPORT')}
                  </Link>
                </li>
                <li className='dropdown__list '>
                  <Link
                    to=''
                    onClick={handleLogoutModal}
                    className='dropdown__list'
                  >
                    <div className='image-logo'>
                      <LogoutImage.default />
                    </div>
                    {t('HOME_PAGE.LOGOUT')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </header>
      <header className='home__navbar d-block  d-lg-none px-3'>
        <div className='d-flex container justify-content-bewteen max__navbar p-0 '>
          {/* <Hamburger.default onClick={toggleSidebar} /> */}
          <MenuIcon onClick={toggleSidebar}  style={{fill:theme?.inActiveMenuIcons??'#00796e'}}/>
          <ul className='navbar-nav max__menunav-list ms-5'>
            {' '}
            <li className='max__notification'>
              <>
                {location?.pathname === '/learn' ||
                location?.pathname === '/learn/category-list' ? (
                  <div className='max__nav-notification-container'>
                    <button id='notification' onClick={handleSearchModal}>
                      <p className='d-none'>search</p>
                      <UniversalSearch.default />
                    </button>
                  </div>
                ) : (
                  <div className='max__nav-notification-container'>
                    <button id='notification' onClick={handleNotificationModal}>
                      <p className='d-none'>notifi</p>
                      {isNewNotificationMob === true ? (
                        <div className='mob__nav-bell'>
                          <HomeNotificationLogoMob.default />
                        </div>
                      ) : (
                        <bellIcon.default />
                      )}
                    </button>
                  </div>
                )}
              </>
            </li>
          </ul>
        </div>
      </header>
      <Sidebar
        isOpen={isSidebarOpen}
        handleLogoutModal={handleLogoutModal}
        onClose={toggleSidebar}
      />
      <SearchModal
        isOpen={isSearchOpen}
        handleLogoutModal={handleSearchModal}
        onClose={toggleSearchbar}
        handleSearch={handleSearch}
        searchInputClose={searchInputClose}
        pluralWordings={pluralWordings}
        showSearch={showSearch}
        searchText={searchText}
        universalSearchLoader={universalSearchLoader}
        searchResults={searchResults}
        LoadingBar={LoadingBar}
        navigateToLearn={navigateToLearn}
      />
      <NotificationModal
        show={showNotificationModal}
        onHide={closeNotificationModal}
        notifications={userNotifcations}
        handleNotificationNavigation={handleNotificationNavigation}
        notificationLoading={notificationLoading}
        setCurrentPage={setCurrentPage}
        page={currentPage}
        count={totalCount}
        markAllAsRead={markAllAsRead}
      />
      <LogoutModal open={showLogoutModal} close={closeLogoutModal} />
    </>
  );
};

export default Navbar;
