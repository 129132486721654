import React from 'react';
import { toast } from 'react-toastify';
import { ErrorToastify, SuccessToastify } from '../../../assets';

const CustomToast = ({ logo, message }) => {
  return (
    <div className={`mx-1 d-flex justify-content-center align-items-center`}>
      {logo === 'success' ? (
        <SuccessToastify.default />
      ) : (
        <ErrorToastify.default />
      )}
      <span className=''>{message}</span>
    </div>
  );
};

const Toast = {
  success: (message) =>
    toast(<CustomToast logo='success' message={message} />, {
      autoClose: 2000,
    }),
  error: (message) =>
    toast(<CustomToast logo='error' message={message} />, {
      autoClose: 2000,
    }),
};

export default Toast;
