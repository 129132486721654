import React from 'react';
import Modal from './Modal';
import { LogoutIcon } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAuth } from '../../../state/slices/loginSlice.';
import { removeLearnerDetails } from '../../../state/slices/learnerDetailSlice';
import { removeActiveSeason } from '../../../state/slices/seasonSlice';
import { useTranslation } from 'react-i18next';

const LogoutModal = ({ open, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function clearAllCookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie =
        cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  }
  const handleLogout = () => {
    dispatch(removeAuth());
    dispatch(removeLearnerDetails());
    localStorage.clear();
    clearAllCookies();
    navigate('/login');
  };

  return (
    <div>
      <Modal button={false} open={open} className='logout_modal'>
        <div className='logout_icon'>
          <LogoutIcon.default />
        </div>

        <div className='logout_header_name'>{t('LOGOUT.TITLE')}</div>

        <div className='logout_button_wrapper'>
          <div className='d-flex'>
            <div className='col-12 col-lg-6 col-md-12 '>
              <button
                className='w-100 button__gradient w-100 button__gradient d-flex w-100 justify-content-end'
                onClick={handleLogout}
              >
                <div class='module-border-wrap'>
                  <div class='module'>
                    {' '}
                    <p>{t('LOGOUT.YES')}</p>{' '}
                  </div>
                </div>
              </button>
            </div>
            <div className='col-12 col-lg-6 col-md-12 '>
              <button className='filled__button w-250' onClick={close}>
                {t('LOGOUT.NO')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LogoutModal;
