import React from 'react';

const CustomButton = ({ disabled, text, disabledText, handleClick, type }) => {
  return (
    <div>
      <button
        className={disabled ? 'filled__disabled' : 'filled__custombutton'}
        onClick={handleClick}
        disabled={((type === 'cert' && disabled)||(type === 'challenge' && disabled))}
      >
        {disabled ? disabledText : text}
      </button>
    </div>
  );
};

export default CustomButton;
