import React, { lazy, Suspense } from 'react';
const Home = lazy(() => import('../pages/home'));
const Error = lazy(() => import('../pages/error'));
const Error501 = lazy(() => import("../pages/501"));
const Login = lazy(() => import('../pages/login'));
const Learn = lazy(() => import('../pages/learn'));
const Layout = lazy(() => import('../components/Layout/Layout'));
const ProtectedRoute = lazy(() => import('./privateRoute'));
const Todo = lazy(() => import('../pages/todo'));
const Rankings = lazy(() => import('../pages/rankings'));
const Transcript = lazy(() => import('../pages/transcript'));
const ResetPassword = lazy(() =>
  import('../components/Common/login/ResetPassword')
);
const ResetPasswordSuccessful = lazy(() =>
  import('../components/Common/login/ResetPasswordSuccessful')
);

const Updateaccountpwd = lazy(() =>
  import('../components/Common/login/updateaccountpwd')
);
const UpdatePasswordSuccessful = lazy(() =>
  import('../components/Common/login/UpdatePasswordSuccessful')
);
const Help = lazy(() => import('../pages/help'));
const Profile = lazy(() => import('../pages/profile'));
const QuestionsNavbar = lazy(() =>
  import('../components/Common/Navbar/QuestionsNavbar')
);
const ChallengesDetail = lazy(() =>
  import('../pages/Challenges/ChallengesDetail')
);
const ChallengesQuestions = lazy(() =>
  import('../pages/questions/ChallengesQuestions')
);
const ChallengesCompleted = lazy(() =>
  import('../pages/Challenges/ChallengesCompleted')
);
const ChallengeSquadDetails = lazy(() =>
  import('../pages/questions/ChallengeSquadDetails')
);
const SquadDetails = lazy(() => import('../components/Profile/SquadDetails'));
const CategoryListing = lazy(() =>
  import('../pages/LearnCategory/CategoryListing')
);
const CategoryDetail = lazy(() =>
  import('../pages/LearnCategory/CategoryDetail')
);
const SubjectDetail = lazy(() =>
  import('../pages/LearnCategory/SubjectDetail')
);
const TopicDetail = lazy(() => import('../pages/LearnCategory/TopicDetail'));
const CategoryFlashCard = lazy(() =>
  import('../pages/LearnCategory/CategoryFlashCard')
);
const CertQuestions = lazy(() =>
  import('../components/ToDo/questcerttab/CertQuestions')
);
const Score = lazy(() => import('../components/ToDo/questcerttab/Score'));
const StartQuizQuestions = lazy(() =>
  import('../pages/LearnCategory/start-quiz-questions')
);
const CongratualtionsScreen = lazy(() =>
  import('../components/Challenges/CongratualtionsScreen')
);
const SquadPerformance = lazy(() =>
  import('../components/Challenges/SquadPerformance')
);
const TermsAndCondition = lazy(() =>
  import('../components/Common/TermsAndPrivacy/TermsAndCondition')
);
const PrivacyPolicy = lazy(() =>
  import('../components/Common/TermsAndPrivacy/PrivacyPolicy')
);
const ChangeProfilePassword = lazy(() =>
  import('../components/Common/login/ChangeProfilePassword')
);
const SamlLogin = lazy(() => import('../components/Common/login/SamlLogin'));
const QuestDetails = lazy(() =>
  import('../components/ToDo/quest/QuestDetails')
);
const QuestpptCard = lazy(() =>
  import('../components/ToDo/quest/QuestpptCard')
);
const QuestQuestions = lazy(() =>
  import('../components/ToDo/quest/QuestQuestions')
);
const QuestpdfCard = lazy(() =>
  import('../components/ToDo/quest/QuestpdfCard')
);
const QuestVideoCard = lazy(() =>
  import('../components/ToDo/quest/QuestVideoCard')
);
const QuestFlashCard = lazy(() =>
  import('../components/ToDo/quest/QuestFlashCard')
);
const QuestScore = lazy(() => import('../components/ToDo/quest/QuestScore'));
const IndividualPerformance = lazy(() =>
  import('../components/Challenges/IndividualPerformance')
);

const Success = lazy(() => import('../components/Common/success/Success'));

import { QuizScore } from '../pages/LearnCategory/quiz-score';
import QuestNewFlashCard from '../components/ToDo/quest/QuestNewFlashCard';
import QuizMe from '../pages/LearnCategory/QuizMe';

export const appRoutes = [
  {
    // routes with Layout
    element: <Layout />,
    children: [
      {
        // protected routes
        element: <ProtectedRoute />,
        children: [],
      },
      // unprotected routes
    ],
  },
  // routes w/o Layout
  {
    // protected routes
    element: <ProtectedRoute />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Error />
          </Suspense>
        ),
      },
      {
        path: '/error/501',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Error501 />
          </Suspense>
        ),
      },
      {
        path: '/to-do',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Todo />
          </Suspense>
        ),
      },
      {
        path: '/rankings',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Rankings />
          </Suspense>
        ),
      },
      {
        path: '/learn',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Learn />
          </Suspense>
        ),
      },
      {
        path: '/learn/category-list',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryListing />
          </Suspense>
        ),
      },
      {
        path: '/learn/detail',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryDetail />
          </Suspense>
        ),
      },
      {
        path: '/learn/subject-detail',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SubjectDetail />
          </Suspense>
        ),
      },
      {
        path: '/learn/topic-detail',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TopicDetail />
          </Suspense>
        ),
      },

      {
        path: '/learn/flash-card',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryFlashCard />
          </Suspense>
        ),
      },
      {
        path: '/transcript',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Transcript />
          </Suspense>
        ),
      },
      {
        path: '/help',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Help />
          </Suspense>
        ),
      },
      {
        path: '/profile',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Profile />
          </Suspense>
        ),
      },
      {
        path: '/profile/squad-details/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SquadDetails />
          </Suspense>
        ),
      },
      {
        path: '/to-do/challenge/detail/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesDetail />
          </Suspense>
        ),
      },
      {
        path: '/to-do/goals',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuizMe />
          </Suspense>
        ),
      },
      {
        path: '/to-do/cert/start',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesDetail />
          </Suspense>
        ),
      },
      {
        path: '/to-do/challenge/score-details/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesCompleted />
          </Suspense>
        ),
      },
      {
        path: '/to-do/challenge/squad-performance/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <SquadPerformance />
          </Suspense>
        ),
      },
      {
        path: '/to-do/challenge/individual-performance/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <IndividualPerformance />
          </Suspense>
        ),
      },
      {
        path: '/start-challenge-exam/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesQuestions />
          </Suspense>
        ),
      },
      {
        path: '/start-cert-exam',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CertQuestions />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/start',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesDetail />
          </Suspense>
        ),
      },
      {
        path: '/to-do/cert/score',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Score />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/detail/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestDetails />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/scorm',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestpptCard />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/question/start',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengesDetail />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/ppt',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestpptCard />
          </Suspense>
        ),
      },
      {
        path: '/start-quest-exam',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestQuestions />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/pdf',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestpdfCard />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/score',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestScore />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/video',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestVideoCard />
          </Suspense>
        ),
      },
      {
        path: '/to-do/quest/deck/:deckid',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestFlashCard />
          </Suspense>
        ),
      },
      {
        path: '/challenge-squad-detail',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChallengeSquadDetails />
          </Suspense>
        ),
      },
      {
        path: '/team-score',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CongratualtionsScreen />
          </Suspense>
        ),
      },
      {
        path: '/questions/:id',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuestionsNavbar />
          </Suspense>
        ),
      },
      {
        path: '/start-learn-module-quiz/',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StartQuizQuestions />
          </Suspense>
        ),
      },
      {
        path: '/learn/score-details/',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <QuizScore />
          </Suspense>
        ),
      },
      {
        path: '/terms_conditions',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <TermsAndCondition />
          </Suspense>
        ),
      },
      {
        path: '/privacy_policy',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: '/resetpassword',
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ChangeProfilePassword />
          </Suspense>
        ),
      },
    ],
  },
  // unprotected routes
  {
    path: '/login',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/reset_password',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: '/reset_password_successful',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ResetPasswordSuccessful />
      </Suspense>
    ),
  },
  {
    path: '/updateaccount-pwd',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Updateaccountpwd />
      </Suspense>
    ),
  },
  {
    path: '/update_password_successful',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <UpdatePasswordSuccessful />
      </Suspense>
    ),
  },
  {
    path: '/saml/acs',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SamlLogin />
      </Suspense>
    ),
  },
  {
    path: '/success',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Success />
      </Suspense>
    ),
  },
];
