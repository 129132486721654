import React, { useRef, useState } from 'react';
import { CloseButton, UniversalSearch } from '../../../assets';
import { useSelector } from 'react-redux';

import {
  FaqIcon,
  LearnIcon,
  SquadIcon,
  BadgesIcon,
  ActivityIcon,
  LogoutImage,
} from '../../../assets';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { t } from 'i18next';
import EmptySearch, {
  EnterThreeSearch,
} from '../../UI/EmptyScreens/EmptySearch';

const navbarItems = [
  {
    name: 'My Activies',
    img: <ActivityIcon.default />,
    link: '/profile?tab=analytics',
  },
  {
    name: 'Learn by category',
    img: <LearnIcon.default />,
    link: '/learn/category-list',
  },
  {
    name: 'My squads',
    img: <SquadIcon.default />,
    link: '/profile?tab=yourSquad',
  },
  {
    name: 'My badges',
    img: <BadgesIcon.default />,
    link: '/profile?tab=badges',
  },
  { name: 'Help desk', img: <FaqIcon.default />, link: '/help' },
];

const searchArea = () => {
  return (
    <>
      {searchText.length > 2 ? (
        <div className='search__backdrop' onClick={searchInputClose}>
          {universalSearchLoader ? (
            <div className='my-5'>
              <LoadingBar />
            </div>
          ) : (
            <>
              <h2 className='search__header'>
                Search result ({searchResults?.length || 0})
              </h2>
              {searchResults.length > 0 ? (
                searchResults?.map((searchDetails) => (
                  <button
                    className='search__content'
                    key={searchDetails?._id}
                    onClick={() => {
                      navigateToLearn(searchDetails?._id, searchDetails?.type);
                    }}
                  >
                    <div className='search__image'>
                      {searchDetails?.categoryIcon ? (
                        <img src={searchDetails?.categoryIcon} alt='icons' />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='search__text' title={searchDetails?.title}>
                      <h4>{searchDetails?.title}</h4>
                      <p>In {pluralWordings(searchDetails?.type)}</p>
                    </div>
                  </button>
                ))
              ) : (
                <EmptySearch />
              )}
            </>
          )}
        </div>
      ) : searchText.length > 0 && searchText.length < 3 ? (
        <EnterThreeSearch />
      ) : (
        ''
      )}
    </>
  );
};
const SearchModal = ({
  isOpen,
  onClose,
  handleLogoutModal,
  handleSearch,
  searchInputClose,
  pluralWordings,
  showSearch,
  searchText,
  universalSearchLoader,
  searchResults,
  LoadingBar,
  navigateToLearn,
}) => {
  const searchRef = useRef();
  const location = useLocation();
  const learnerDetail = useSelector((state) => state.learnerDetail);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (showSearch) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  console.log(learnerDetail, 'this is values');
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='overlay' onClick={onClose}></div>
      <div className='sidebar-content  d-block d-lg-none'>
        <button className='close-button' onClick={onClose}>
          <p className='d-none'>close</p>
          <CloseButton.default />
        </button>
        <div className=''>
          <div className='search__wrapper '>
            <label className='d-none' for='max-search-main'>
              Type something here:
            </label>
            <input
              ref={searchRef}
              placeholder={t('HOME_PAGE.UNIVERSAL_SEARCH_PLACEHOLDER')}
              value={searchText}
              className='search_box expand__searchbar'
              onChange={handleSearch}
              name='max-search-main'
              id='max-search-main'
            />
            <div>
              <div className='search__icon'>
                <UniversalSearch.default />
              </div>
            </div>
            {searchText.length > 2 ? (
              <div className='search__dropdown-mob'>
                {universalSearchLoader ? (
                  <div className='my-5'>
                    <LoadingBar />
                  </div>
                ) : (
                  <>
                    <h2 className='search__header'>
                      Search result ({searchResults?.length || 0})
                    </h2>
                    {searchResults.length > 0 ? (
                      searchResults?.map((searchDetails) => (
                        <button
                          className='search__content'
                          key={searchDetails?._id}
                          onClick={() => {
                            navigateToLearn(
                              searchDetails?._id,
                              searchDetails?.type
                            );
                          }}
                        >
                          <div className='search__image'>
                            {searchDetails?.categoryIcon ? (
                              <img
                                src={searchDetails?.categoryIcon}
                                alt='icons'
                              />
                            ) : (
                              <img
                                src={searchDetails?.subjectIcon}
                                alt='icons'
                              />
                            )}
                          </div>
                          <div
                            className='search__text'
                            title={searchDetails?.title}
                          >
                            <h4>{searchDetails?.title}</h4>
                            <p>In {pluralWordings(searchDetails?.type)}</p>
                          </div>
                        </button>
                      ))
                    ) : (
                      <EmptySearch />
                    )}
                  </>
                )}
              </div>
            ) : searchText.length > 0 && searchText.length < 3 ? (
              <EnterThreeSearch />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
