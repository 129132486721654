import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-bootstrap';
import { GoalsIcon } from '../../../assets';
import ProgressBar from '../../UI/ProgressBar';

const CatergoryCard = ({
  data,
  detail,
  level,
  button,
  topics,

  openExamPage,
  decks,

  levelStatus,
  quizStatus,
}) => {
  const changes = {
    " Awareness ": " Basic ",
    " Explanatory ": " Intermediate ",
    " Practitioner ": " Advanced ",
    "Awareness ": "Basic ",
    "Explanatory ": "Intermediate ",
    "Practitioner ": "Advanced ",
    " Awareness": " Basic",
    " Explanatory": " Intermediate",
    " Practitioner": " Advanced",
    Awareness: "Basic",
    Explanatory: "Intermediate",
    Practitioner: "Advanced",
    AWARENESS: "Basic",
    EXPLANATORY: "Intermediate",
    PRACTITIONER: "Advanced",
    awareness: "Basic",
    explanatory: "Intermediate",
    practitioner: "Advanced",
  };
  const colorToHex = (color) => {
    const byteToHex = (num) => {
      return ('0' + num.toString(16)).slice(-2);
    };
    const colorToRGBA = (color) => {
      var cvs, ctx;
      cvs = document.createElement('canvas');
      cvs.height = 1;
      cvs.width = 1;
      ctx = cvs.getContext('2d');
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, 1, 1);
      return ctx.getImageData(0, 0, 1, 1).data;
    };
    var rgba, hex;
    rgba = colorToRGBA(color);
    hex = [0, 1, 2]
      .map(function (idx) {
        return byteToHex(rgba[idx]);
      })
      .join('');
    return '#' + hex;
  };
  const hexToRgbA = (hex) => {
    let regexp = /^#/;
    if (hex !== undefined && regexp.test(hex)) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',0.6)'
        );
      }
      throw new Error('Bad Hex');
    } else if (hex !== undefined && !regexp.test(hex)) {
      return hexToRgbA(colorToHex(hex));
    }
  };
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  return (
    <>
      <div
        className='max-learn__catergory-card bg-opacity-50'
        style={{ backgroundColor: hexToRgbA(data?.colorr) }}
      >
        {/* <LearnCompliance.default /> */}
        {!decks ? (
          <img
            alt={`Catergory img${data?.title?.slice(0, 5)}..`}
            src={data?.icon}
            style={{ width: '52px', height: '52px' }}
          />
        ) : (
          ''
        )}

        <h4
          className={`pt-3 ${detail ? 'detail-header-title' : ''}`}
          title={data?.title}
        >
          {data?.title?.length > 25
            ? `${data?.title?.slice(0, 25)}..`
            : data?.title}
        </h4>

        <span className='max-learn__catergory-card__category_subject'>
          {data?.count} {topics ? 'Topics' : decks ? 'Decks' : 'Subjects'}
        </span>
        <ProgressBar
          bgcolor='#212121'
          progress={data?.progress ?? 0}
          height={6}
          className='mt-2 progressbar__learn-text'
          marginTop={20}
        />

        {level === true ? (
          <>
            <div className='card_level_wrapper'>
              <img
                src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430418/Award_eocnqe.svg'
                height='20px'
                width='20px'
                alt='level image'
              ></img>{' '}
              &nbsp;
              <span className='card_level'>
                You are at{' '}
                <strong>
                  {/* {data?.level &&
                    data?.level
                      .split(' ') // Split the string into words based on spaces
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      ) // Capitalize the first letter of each word
                      .join(' ')} */}
                  {changes[data.level]}
                </strong>{' '}
                level
              </span>
            </div>
          </>
        ) : (
          ''
        )}

        {levelStatus ? (
          <div className='card_level_wrapper mt-3'>
            <img
              src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430438/whatsapp_status_y6jk7t.svg'
              height='20px'
              width='20px'
              alt='image max stat'
            ></img>{' '}
            &nbsp;
            <span className='card_level'>
              You are at{' '}
              <strong> {capitalizeFirstLetter(data?.levelStatus)}</strong>{' '}
              status
            </span>
          </div>
        ) : (
          ''
        )}

        <div
          className='category_detail category_detail-text-quiz'
          title={data?.description}
        >
          {detail === true && !decks
            ? data?.description?.length > 195
              ? `${data?.description?.slice(0, 195)}...`
              : data?.description
            : ''}
          {detail === true && decks == true
            ? data?.description?.length > 117
              ? `${data?.description?.slice(0, 117)}...`
              : data?.description
            : ''}
          <Tooltip title={data?.description}></Tooltip>
        </div>
        {data?.goalExist === true ? (
          <div className='goals'>
            <hr />
            <h6 className='goal-txt'>
              <GoalsIcon.default /> Goals
            </h6>
            <p className='goals__text'>You need to reach:</p>
            <div className='d-flex'>
              <div className='ps-0 mt-2'>
                <img
                  src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430418/Award_eocnqe.svg'
                  height='20px'
                  width='20px'
                  alt='img caregory '
                ></img>{' '}
                &nbsp;
                <span className='card_level text-details'>
                  {data?.goalObj?.requiredLearnLevel &&
                    changes[data?.goalObj?.requiredLearnLevel]}
                </span>
              </div>
              <div className='ps-2 mt-2'>
                <img
                  src='https://res.cloudinary.com/dysdy7hjr/image/upload/v1685430438/whatsapp_status_y6jk7t.svg'
                  height='20px'
                  width='20px'
                  alt='img caregory detail '
                ></img>{' '}
                &nbsp;
                <span className='card_level text-details'>
                  {data?.goalObj?.requiredStatus &&
                    capitalizeFirstLetter(data?.goalObj?.requiredStatus)}
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {button === true && quizStatus === true ? (
          <div
            className='card_quiz_button cursor-pointer'
            onClick={openExamPage}
          >
            <button>Quiz me</button>
          </div>
        ) : (
          ''
        )}

        {data?.goalExist === true ? (
          <div className='text-center goal-view-link mt-2'>
            {' '}
            <Link to='/to-do?tab=goals'>View Other Goals</Link>{' '}
          </div>
        ) : null}
        <div
          className={
            detail === true
              ? 'quarter-circle detail-quarter-circle'
              : 'quarter-circle'
          }
          style={{ background: data?.colorr }}
        ></div>
      </div>
    </>
  );
};

export default CatergoryCard;
