import React from 'react';
import MathJax from 'react-mathjax-preview';
import parse from 'html-react-parser';

export const useQuery = (params) => {
  const data = {};
  params
    .substring(1)
    .split('&')
    .map((val) => {
      if (val) {
        const indi = val.split('=');
        data[indi[0]] = indi[1];
      }
    });
  return data;
};

export const renderText = (text) => {
  const mathtype = <MathJax math={String.raw`${text}`} />;
  const texttype = parse(text);
  let textType = '';

  const loop = (item) => {
    // string.forEach((item) => {
    if (item?.type !== 'math') {
      if (item?.props?.children?.length > 0) {
        return loop(item?.props?.children);
      }
    } else {
      textType = 'math';
    }
    // return null;
    // });
  };

  loop(texttype);

  if (textType !== 'math') {
    return texttype;
  }
  return mathtype;
};

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });

export const rankDetail = (rank) => {
  const subText = ['th', 'st', 'nd', 'rd'];
  const ending = rank % 100;
  return subText[(ending - 20) % 10] || subText[ending] || subText[0];
};

// export const getCookie = (cookieName) => {
//   const cookies = document.cookie.split(';');

//   for (let i = 0; i < cookies.length; i++) {
//     const cookie = cookies[i].trim();

//     // Check if this is the cookie you're looking for
//     if (cookie.startsWith(cookieName + '=')) {
//       // Extract and return the cookie value
//       return cookie.substring(cookieName.length + 1);
//     }
//   }

//   // If the cookie doesn't exist, return null or handle it accordingly
//   return null;
// };

// A simple encryption function for demonstration purposes
function encryptData(data, encryptionKey) {
  // Replace this with a more robust encryption algorithm
  const encryptedData = btoa(data); // Base64 encoding for simplicity
  return encryptedData;
}

export const setCookie = (name, value, daysToExpire, encryptionKey) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysToExpire);

  const encryptedValue = encryptData(value, encryptionKey);

  const cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(
    encryptedValue
  )}; expires=${expirationDate.toUTCString()}; path=/`;

  document.cookie = cookieString;
};

// Function to decode Base64-encoded data
function decryptData(encryptedData) {
  return atob(encryptedData); // Base64 decoding
}

// Function to read a cookie and decrypt its value
export const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split('; ');

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].split('=');
    if (cookie[0] === name) {
      const encryptedValue = cookie[1];
      const decryptedValue = decryptData(encryptedValue);
      return decryptedValue;
    }
  }

  return null;
};
