
// const defaultTheme = {

//   //font
//   PrimaryFont: "#CC0433",


//   //icons
//   inActiveMenuIcons: "#CC0433",
//   activeMenuIcons: "#F6F7FA",

//   containedButtonBackground: "#CC0433",
//   containedButtonBackgroundActive: "#CC0433",
  
//   containedButtonBackgroundHover: "#525252",
//   outlinedButtonBackground: "#CC0433",
  
//   selectedCheckbox: "#CC0433",
//   selectedRadio: "#CC0433",
//   loader: "#CC0433",

//   loginBackgroundImage: "url('https://www.aaos.org/globalassets/aaos-global/aaos-logo-image-1.svg')",
//   loginLogoImage: "https://www.aaos.org/globalassets/aaos-global/aaos-logo-image-1.svg",
//   icoIcon:"https://www.aaos.org/contentassets/72faca73833746f98645bc3cea94db5f/favicon_01.ico"
// };

const defaultTheme = null;

const initialState = {
  theme: null
};

try {
  const storedTheme = localStorage.getItem('theme');
  if (storedTheme) {
    initialState.theme = JSON.parse(storedTheme);
  }
} catch (error) {
  console.error("Error reading theme from localStorage:", error);
  initialState.theme = defaultTheme;
}

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETTHEME':
      if (action?.data) {
        try {
          localStorage.setItem('theme', JSON.stringify(action.data));
        } catch (error) {
          console.error("Error saving theme to localStorage:", error);
        }
      }
      return { ...state, theme: action.data };
    default:
      return state;
  }
};



export const setTheme = (theme) => {
  return (dispatch) => {
    dispatch({ type: "SETTHEME", data: theme});
  };
};
export const SETTHEME = 'SETTHEME';

export default themeReducer;;
