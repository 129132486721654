import React from 'react';
import ReactDom from 'react-dom';
import { CloseButton } from '../../../assets';
import '../../../styles/common/modal/modal.scss';

const Modal = ({
  children,
  open,
  close,
  className = '',
  containerClass = '',
  button = true,
}) => {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={`backdrop ${containerClass}`}>
        <div className={`modal__wrapper ${className}`}>{children}</div>
        {button ? (
          <button className='close__button' onClick={close}>
            <p className='d-none'>cls</p>
            <CloseButton.default />
          </button>
        ) : (
          ''
        )}
      </div>
    </>,
    document.getElementById('overlays')
  );
};

export default Modal;
